import React from "react"
import { Link, graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import LoginForm from '../components/loginForm'
import SEO from "../components/seo"
import $ from 'jquery'
import 'slick-carousel'

import "../common/css/top.css"

// 画像読み込み
import Main1 from "../common/images/main1.jpg"
import Main2 from "../common/images/main2.jpg"
import Main3 from "../common/images/main3.jpg"
import Main4 from "../common/images/main4.jpg"
import Main5 from "../common/images/main5.jpg"
import TopMenu01 from "../images/topmenu01@2x.png"
import TopMenu02 from "../images/topmenu02@2x.png"
import TopMenu03 from "../images/topmenu03@2x.png"
import TopMenu04 from "../images/topmenu04@2x.png"
import TopMenu05 from "../images/topmenu05@2x.png"
import TopMenu06 from "../images/topmenu06@2x.png"
import TopSideMenu01 from "../images/topsidemenu01@2x.png"
import TopSideMenu02 from "../images/topsidemenu02@2x.png"

class IndexPage extends React.Component {

  showCat = (n) => {
    var l = ".top_news_cat" + n;
    $(".top_news_list ul li").css("display", "block");
    if (n != 0) {
      $(".top_news_list ul li").not(l).css("display", "none");
    } else {
      $(".top_news_list ul li:nth-of-type(n+11)").css("display", "none");
    }
  }

  componentDidMount = () => {
      $('#top_image').slick({
        fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      arrows: false
      });
      
      $(".top_news_list ul li:nth-of-type(n+11)").css("display","none");
      
      $(".top_news_catList li a").on("click",function(){
        $(".top_news_catList li a").removeClass("current");
        $(this).addClass("current");
        $(".top_news_list ul"+$(this).attr("href")).show();
        $(".top_news_list ul li")
      });
  }

  render = (data) => {
    return (
      <Layout>
        <SEO title="home" />
        <div id="top_imageArea">
          <div id="top_image" className="mb10">
            <div>
              <img src={Main1} alt="" className="imgFit"></img>
            </div>
            <div>
              <img src={Main2} alt="" className="imgFit"></img>
            </div>
            <div>
              <img src={Main3} alt="" className="imgFit"></img>
            </div>
            <div>
              <img src={Main4} alt="" className="imgFit"></img>
            </div>
            <div>
              <img src={Main5} alt="" className="imgFit"></img>
            </div>
          </div>
          <div className="top_imageTxt">
            <p className="mc">鉄道施設を守る人々を支えます</p>
          </div>
          <div className="top_imageBtn">
            <ul>
              <li>
                <a href="#">協会について</a>
              </li>
              <li>
                <a href="#">支部活動</a>
              </li>
            </ul>
          </div>
        </div>
        <div id="top_main">
          <div id="top_main_inner1">
            <div id="top_news">
              <h2>NEWS&nbsp;&amp;&nbsp;TOPICS</h2>
              <div className="top_news_inner">
                <div className="top_news_menu">
                  <ul className="top_news_catList">
                    <li>
                      <a onClick={() => this.showCat('0')} className="current">すべて</a>
                    </li>
                    <li>
                      <a onClick={() => this.showCat('1')}>協会からのお知らせ</a>
                    </li>
                    <li>
                      <a onClick={() => this.showCat('2')}>保安講習会情報</a>
                    </li>
                    <li>
                      <a onClick={() => this.showCat('3')}>お知らせ</a>
                    </li>
                  </ul>
                </div>
                <div className="top_news_list">
                  <ul>
                    <li className="top_news_cat1">
                      <span className="top_news_date">2020.10.05</span>
                      <span className="label_base label01">協会からのお知らせ</span>
                      <span className="top_news_title">
                        <a href="#">2020年度第35回総合技術講演会開催方法(オンライン実施)について</a>
                      </span>
                    </li>
                    <li className="top_news_ca2">
                      <span className="top_news_date">2020.10.02</span>
                      <span className="label_base label02">保安講習会情報</span>
                      <span className="top_news_title">
                        <a href="#">土木工事標準仕様書（JR東日本編）の予約を開始しました</a>
                      </span>
                    </li>
                    <li className="top_news_cat3">
                      <span className="top_news_date">2020.07.16</span>
                      <span className="label_base label03">お知らせ</span>
                      <span className="top_news_title">
                        <a href="#">講習会情報が更新されました。（JR北海道）</a>
                      </span>
                    </li>
                    <li className="top_news_cat1">
                      <span className="top_news_date">2020.10.05</span>
                      <span className="label_base label01">協会からのお知らせ</span>
                      <span className="top_news_title">
                        <a href="#">2020年度第35回総合技術講演会開催方法(オンライン実施)について</a>
                      </span>
                    </li>
                    <li className="top_news_cat2">
                      <span className="top_news_date">2020.10.02</span>
                      <span className="label_base label02">保安講習会情報</span>
                      <span className="top_news_title">
                        <a href="#">土木工事標準仕様書（JR東日本編）の予約を開始しました</a>
                      </span>
                    </li>
                    <li className="top_news_cat3">
                      <span className="top_news_date">2020.07.16</span>
                      <span className="label_base label03">お知らせ</span>
                      <span className="top_news_title">
                        <a href="#">講習会情報が更新されました。（JR北海道）</a>
                      </span>
                    </li>
                    <li className="top_news_cat1">
                      <span className="top_news_date">2020.10.05</span>
                      <span className="label_base label01">協会からのお知らせ</span>
                      <span className="top_news_title">
                        <a href="#">2020年度第35回総合技術講演会開催方法(オンライン実施)について</a>
                      </span>
                    </li>
                    <li className="top_news_cat2">
                      <span className="top_news_date">2020.10.02</span>
                      <span className="label_base label02">保安講習会情報</span>
                      <span className="top_news_title">
                        <a href="#">土木工事標準仕様書（JR東日本編）の予約を開始しました</a>
                      </span>
                    </li>
                    <li className="top_news_cat3">
                      <span className="top_news_date">2020.07.16</span>
                      <span className="label_base label03">お知らせ</span>
                      <span className="top_news_title">
                        <a href="#">講習会情報が更新されました。（JR北海道）</a>
                      </span>
                    </li>
                    <li className="top_news_cat1">
                      <span className="top_news_date">2020.10.05</span>
                      <span className="label_base label01">協会からのお知らせ</span>
                      <span className="top_news_title">
                        <a href="#">2020年度第35回総合技術講演会開催方法(オンライン実施)について</a>
                      </span>
                    </li>
                    <li className="top_news_cat1">
                      <span className="top_news_date">2020.10.05</span>
                      <span className="label_base label01">協会からのお知らせ</span>
                      <span className="top_news_title">
                        <a href="#">2020年度第35回総合技術講演会開催方法(オンライン実施)について</a>
                      </span>
                    </li>
                    <li className="top_news_cat2">
                      <span className="top_news_date">2020.10.02</span>
                      <span className="label_base label02">保安講習会情報</span>
                      <span className="top_news_title">
                        <a href="#">土木工事標準仕様書（JR東日本編）の予約を開始しました</a>
                      </span>
                    </li>
                    <li className="top_news_cat3">
                      <span className="top_news_date">2020.07.16</span>
                      <span className="label_base label03">お知らせ</span>
                      <span className="top_news_title">
                        <a href="#">講習会情報が更新されました。（JR北海道）</a>
                      </span>
                    </li>
                    <li className="top_news_cat1">
                      <span className="top_news_date">2020.10.05</span>
                      <span className="label_base label01">協会からのお知らせ</span>
                      <span className="top_news_title">
                        <a href="#">2020年度第35回総合技術講演会開催方法(オンライン実施)について</a>
                      </span>
                    </li>
                    <li className="top_news_cat2">
                      <span className="top_news_date">2020.10.02</span>
                      <span className="label_base label02">保安講習会情報</span>
                      <span className="top_news_title">
                        <a href="#">土木工事標準仕様書（JR東日本編）の予約を開始しました</a>
                      </span>
                    </li>
                    <li className="top_news_cat3">
                      <span className="top_news_date">2020.07.16</span>
                      <span className="label_base label03">お知らせ</span>
                      <span className="top_news_title">
                        <a href="#">講習会情報が更新されました。（JR北海道）</a>
                      </span>
                    </li>
                    <li className="top_news_cat1">
                      <span className="top_news_date">2020.10.05</span>
                      <span className="label_base label01">協会からのお知らせ</span>
                      <span className="top_news_title">
                        <a href="#">2020年度第35回総合技術講演会開催方法(オンライン実施)について</a>
                      </span>
                    </li>
                    <li className="top_news_cat2">
                      <span className="top_news_date">2020.10.02</span>
                      <span className="label_base label02">保安講習会情報</span>
                      <span className="top_news_title">
                        <a href="#">土木工事標準仕様書（JR東日本編）の予約を開始しました</a>
                      </span>
                    </li>
                    <li className="top_news_cat3">
                      <span className="top_news_date">2020.07.16</span>
                      <span className="label_base label03">お知らせ</span>
                      <span className="top_news_title">
                        <a href="#">講習会情報が更新されました。（JR北海道）</a>
                      </span>
                    </li>
                    <li className="top_news_cat1">
                      <span className="top_news_date">2020.10.05</span>
                      <span className="label_base label01">協会からのお知らせ</span>
                      <span className="top_news_title">
                        <a href="#">2020年度第35回総合技術講演会開催方法(オンライン実施)について</a>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="top_news_allList">
                  <a href="#">一覧を見る</a>
                </div>
              </div>
            </div>
            <div id="top_sidemenu">
              <LoginForm></LoginForm>
              <div className="top_contact_btn">
                <a href="#">ご意見・ご要望</a>
              </div>
              <div className="top_side_subMenu">
                <ul>
                  <li>
                    <a href="emag.html">
                      <img src={TopSideMenu01} alt="協会誌電子版" className="imgFit"></img>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={TopSideMenu02} alt="保有図書検索" className="imgFit"></img>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="top_menu">
          <h2>CONTENTS</h2>
          <div className="top_menu_list">
            <ul>
              <li>
                <a href="#">
                  <img src={TopMenu01} alt="" className="imgFit"></img>
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={TopMenu02} alt="" className="imgFit"></img>
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={TopMenu04} alt="" className="imgFit"></img>
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={TopMenu05} alt="" className="imgFit"></img>
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={TopMenu06} alt="" className="imgFit"></img>
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={TopMenu03} alt="" className="imgFit"></img>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date] }) {
      edges {
        node {
          title
          excerpt
          slug
        }
      }
    }
  }`

  export default IndexPage;