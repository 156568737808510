import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { login, isLoggedIn, logout } from '../services/auth.js'

class LoginForm extends React.Component {
  state = {
    Password: "",
    MemberNo: "",
    LoginMessage: ""
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleLogout = event => {
    event.preventDefault();
    logout(() => {
      window.location.reload();
    });
  }

  handleLogin = async event => {
    event.preventDefault()
    console.log('call handleSubmit');

    if (await login(this.state)) {
      this.setState({
        Password: "",
        MemberNo: "",
        LoginMessage: ""
      });
    } else {
      this.setState({
        LoginMessage: "会員番号、またはパスワードが異なります。"
      });
    }
  }

  render = () => {
    if (isLoggedIn()) {
      return (
        <div className="top_login">
          <h2 className="top_login_head">会員情報</h2>
          <form className="top_login_form" onSubmit={this.handleLogout}>
            <div className="top_login_inner">
              <label>会員番号</label>
              <input type="text" readOnly="readOnly" className="top_login_input" name="MemberNo" value={this.state.MemberNo} ></input>
            </div>
            <div className="top_login_btn">
              <input type="submit" value="ログアウト"  ></input>
            </div>
          </form>
          <p className="top_login_notice mb1em">会員IDを忘れた方は
            <a href="#">こちら</a>
            <br />パスワードを忘れた方は
            <a href="#">こちら</a>
          </p>
          <p className="top_login_notice">このページは会員専用ページです。
            <br />会員でない方はご入会をお勧めいたします。入会のご案内は
            <a href="#">こちら</a>
            <br />会員番号がわからない方は03-5846-5300又は054-3489へ連絡をしてください。
            <br />パスワードのわからない方は協会誌の当月号をご覧ください。
          </p>
        </div>
      )
    } else {
      // not loggedin
      return (
        <div className="top_login">
          <h2 className="top_login_head">会員ログイン</h2>
          <form className="top_login_form" onSubmit={this.handleLogin}>
            <div className="top_login_inner">
              <label>会員番号</label>
              <input type="text" className="top_login_input" name="MemberNo" value={this.state.MemberNo} onChange={this.handleInputChange}></input>
            </div>
            <div className="top_login_inner">
              <label>パスワード</label>
              <input type="text" className="top_login_input" name="Password" value={this.state.Password} onChange={this.handleInputChange}></input>
            </div>
            <div className="top_login_btn">
              <input type="submit" value="ログイン" ></input>
            </div>
          </form>
          <p className="top_login_notice mb1em">会員IDを忘れた方は
            <a href="#">こちら</a>
            <br />パスワードを忘れた方は
            <a href="#">こちら</a>
          </p>
          <p className="top_login_notice">このページは会員専用ページです。
            <br />会員でない方はご入会をお勧めいたします。入会のご案内は
            <a href="#">こちら</a>
            <br />会員番号がわからない方は03-5846-5300又は054-3489へ連絡をしてください。
            <br />パスワードのわからない方は協会誌の当月号をご覧ください。
          </p>
        </div>
      )
    }
  }

}

export default LoginForm
