import axios from "axios"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.sessionStorage.getItem("gatsbyUser")
    ? JSON.parse(window.sessionStorage.getItem("gatsbyUser"))
    : {}

const setUser = user =>
  window.sessionStorage.setItem("gatsbyUser", JSON.stringify(user))

export const login = async ({ MemberNo, Password }) => {
  console.log('call handleLogin');
  const postData = {
    MemberNo: MemberNo,
    Password: Password,
  };
  try {
    // TODO:環境によって変える必要あり
    const res = await axios.post('https://5udylqjg3h.execute-api.ap-northeast-1.amazonaws.com/develop/login', postData, 
      { headers: { 'X-API-Key': '3RD0yu5cbe4yAF3yHWlGj5qETCR30OCP6wsxCJH6' }
    });
    
    if (res.data.statusCode === 200){
      // ログイン完了
      setUser({
        MemberNo: MemberNo
      })
      return true;
    } else {
      return false;
    }
  } catch(error) {
    console.log(error);
    return false;
  }
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.MemberNo
}

export const logout = callback => {
  setUser({})
  callback()
}